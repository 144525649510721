<template>
  <picture v-if="picture">
    <source
      v-for="(format, idx) in pictureFormats"
      :key="idx"
      :srcset="format.url"
      :media="'(max-width: ' + format.width + 'px)'"
      :height="format.height"
      :width="format.width"
      :type="format.mime"
    >
    <img
      :src="picture.url"
      :alt="picture.alternativeText"
      :height="picture.height"
      :width="picture.width"
      :type="picture.mime"
    >
  </picture>
</template>

<script>
export default {
  name: 'ResponsiveImage',
  props: {
    picture: {
      type: Object || null,
      default: () => ({})
    }
  },
  computed: {
    pictureFormats() {
      if (!this.picture || !this.picture.formats) {
        return []
      }
      return Object.entries(this.picture.formats)
        .map(([, value]) => ({...value}))
        .sort((x, y) => (x.width ?? 0) - (y.width ?? 0)) || []
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: auto;
}
</style>
